import React, {useState, useEffect} from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { mediaIntegration } from './config.jsx';

function ChatMainMessageBodyCardSender ({ time, message, messageType, attachments }) {
    let attachmentObjects = null;
    const [contentParts, setContentParts] = useState([]);
    const [showIntegration, setShowIntegration] = useState(false);

    function parseContent(input) {
        // const regex = /\{["']files["']\s*:\s*\[\{\s*.*?\]\}/gs;
        const regex = /\[\[\[.*?\]\]\]/gs;
        let match;
        let lastIndex = 0;
        const parts = [];
      
        while ((match = regex.exec(input)) !== null) {
            const jsonStart = match.index;
            const jsonEnd = regex.lastIndex;
            const beforeText = input.slice(lastIndex, jsonStart);
            if (beforeText.trim()) {
                parts.push({ type: 'text', content: beforeText });
            }
            const jsonString = match[0].slice(3,-3);
            try {
                const json = JSON.parse(jsonString);
                if (json.source && Array.isArray(json.source)) {
                    if (typeof json.source === 'object'){
                        parts.push({ type: 'json', content: json.source });
                    } else {
                        parts.push({ type: 'json', content: JSON.parse(json.source) });
                    }
                } else {
                // 如果 JSON 中没有 source 或不是数组，则作为文本处理
                    parts.push({ type: 'text', content: jsonString });
                }
            } catch (error) {
                // 如果解析失败，则作为文本处理
                parts.push({ type: 'text', content: jsonString });
            }
            lastIndex = jsonEnd;
        }
      
        // 添加剩余的文本
        const remainingText = input.slice(lastIndex);
        if (remainingText.trim()) {
            parts.push({ type: 'text', content: remainingText });
        }
        
        return parts;
    }
    
    // 尝试解析 attachments 字符串为 JSON 对象
    if (attachments && typeof attachments === 'string') {
        try {
            attachmentObjects = JSON.parse(attachments);
        } catch (error) {
            console.error("Error parsing attachment JSON:", error);
        };
    } else {
        attachmentObjects = attachments
    };    
    
    useEffect(() => {
        if (mediaIntegration.includes(messageType)) {
            setShowIntegration(true);
        } else {
            setShowIntegration(false)
        };
    }, [messageType]);

    useEffect(() => {
        if(showIntegration) {
            setContentParts(parseContent(message));
        } else {
            setContentParts([{ type: 'text', content: message }])
        };
        // setContentParts(parseContent(message));
    }, [showIntegration, message, messageType])

    useEffect(() => {
        // console.log(79, contentParts);
    }, [contentParts]);
    
    const showAttachments = () => {
        if (!attachmentObjects) return null;

        return attachmentObjects.map((item, index) => {
            if (item.filetype === 'image') {
                return (
                    <Card.Text className="preserve-whitespace">
                        <div key={index} className="attachment-image">
                            <a href={item.image_url} target="_blank" rel="noopener noreferrer">
                                <img src={item.thumbnail_url} alt="Attachment" style={{ maxWidth: '100%', cursor: 'pointer' }} />
                            </a>
                        </div>
                    </Card.Text>
                );
            }
            // 可以在这里添加更多的条件来处理其他类型的附件
        });
    };

    // 渲染内容部分，仅显示 'text' 类型的内容
    const showPartsContent = () => {
        return contentParts
            .filter(part => part.type === 'text') // 仅保留 'text' 类型
            .map((part, index) => <span key={index}>{part.content}</span>);
    };
    
    return (
        <Card className="mb-3 no-border-card">
            <Row className="g-0">
                
                <Col xs={12} md={12}>
                    <Card.Body className="card-sender bg-success-subtle text-success-emphasis">
                        {showAttachments()}
                        <Card.Text className="preserve-whitespace">
                            { showIntegration ? showPartsContent() : message}
                        </Card.Text>
                    </Card.Body>
                    <div className='d-flex justify-content-between text-secondary'>
                        <small className="chat-card-footer">{time}</small>
                    </div>
                    
                </Col>
            </Row>
        </Card>
    );
};

export default ChatMainMessageBodyCardSender;