import React, { useEffect, useState } from 'react';
import MarkdownRender from './markdow_render.jsx';

function ChatMainMessageBodyCardReceiverIntegration({ data }) {
    const [contentParts, setContentParts] = useState([]);

    function parseContent(input) {
        // const regex = /\{["']results["']\s*:\s*\[\{\s*.*?\]\}/gs;
        const regex = /\[\[\[.*?\]\]\]/gs;

        let match;
        let lastIndex = 0;
        const parts = [];
      
        while ((match = regex.exec(input)) !== null) {
            const jsonStart = match.index;
            const jsonEnd = regex.lastIndex;
            const beforeText = input.slice(lastIndex, jsonStart);
            if (beforeText.trim()) {
                parts.push({ type: 'text', content: beforeText });
            }
            const jsonString = match[0].slice(3,-3);
            try {
                const json = JSON.parse(jsonString);
                if (json.results && Array.isArray(json.results)) {
                    if (typeof json.results === 'object'){
                        parts.push({ type: 'json', content: json.results });
                    } else {
                        parts.push({ type: 'json', content: JSON.parse(json.results) });
                    }
                } else {
                // 如果 JSON 中没有 results 或不是数组，则作为文本处理
                    parts.push({ type: 'text', content: jsonString });
                }
            } catch (error) {
                // 如果解析失败，则作为文本处理
                // console.error(error);
                parts.push({ type: 'text', content: jsonString });
            }
            lastIndex = jsonEnd;
        }
      
        // 添加剩余的文本
        const remainingText = input.slice(lastIndex);
        if (remainingText.trim()) {
            parts.push({ type: 'text', content: remainingText });
        }
      
        return parts;
    }
    
    useEffect(() => {
        setContentParts(parseContent(data))
    }, [data]);

    return (
        <>
            {contentParts.map((part, index) => {
                if (part.type === 'text') {
                    return (
                        <MarkdownRender key={index}>
                            {part.content}
                        </MarkdownRender>
                    );
                } else if (part.type === 'json' && Array.isArray(part.content)) {
                    return part.content.map((image, imgIndex) => (
                        <div key={`${index}-${imgIndex}`}>
                            <a href={image.image_url} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={image.thumbnail_url}
                                    alt={`Image ${imgIndex + 1}`}
                                    style={{ maxWidth: '100%', cursor: 'pointer' }}
                                />
                            </a>
                        </div>
                    ));
                } else {
                    return <></>;
                }
            })}
        </>
    );
};

export default ChatMainMessageBodyCardReceiverIntegration;