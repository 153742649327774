import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';


const CodeBlock = ({ language, value }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(value);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1000); // Reset isCopied after 2 seconds
        } catch (err) {
            console.error('Failed to copy!', err);
        }
    };

    // 直接在这里设置自定义样式
    const customStyle = {
        ...solarizedlight,
        'pre[class*="language-"]': {
            ...solarizedlight['pre[class*="language-"]'],
            fontSize: '1.07em', // 或者你可以使用像素值，例如 '16px'
            // backgroundColor: '#2f2f2f', // 设置你想要的背景颜色
            paddingTop: '25px',
        },
        'code[class*="language-"]': {
            ...solarizedlight['code[class*="language-"]'],
            fontSize: '1.07em', // 确保这里的字体大小与上面的一致
            // 其他样式...
        },
    };

    return (
        <div style={{ position: 'relative' }}>
            <SyntaxHighlighter language={language} style={customStyle}>
                {value}
            </SyntaxHighlighter>
            <Button
                className='btn-outline-success no-border-button'
                onClick={handleCopy}>
                <i className={`bi ${isCopied ? 'bi-check2' : 'bi-clipboard'}`}></i>                
            </Button>
        </div>
    );
};

export default CodeBlock;