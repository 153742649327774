import React, { useState, useRef, useEffect, } from 'react';
import { Button, Modal, Row, Col, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { toolMidjourney, toolPhotoMaker } from './config.jsx';
import ImageActions from './chat_main_files_container_send_image.jsx';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function FilesContainerSend({ show, onHide, files, fileType, setSendMessage, chatClassName, price = 2 }) {
    const [isPlaying, setIsPlaying] = useState(true);    
    const [textValue, setTextValue] = useState('');
    const textareaRef = useRef(null);
    const sendButtonRef = useRef(null);
    const [textareaClassName, setTextareaClassName] = useState("form-control border-1 mt-0 chat-main-message-body-container-textarea");
    
    const [selectedButtons, setSelectedButtons] = useState({});
    const [cwValue, setcwValue] = useState(50);
    const [showCW, setShowCW] = useState(false);

    // 更新特定文件的选中按钮
    const setSelectedForFile = (fileIndex, selectedButtonId) => {
        // console.log(37, fileIndex, selectedButtonId);
        setSelectedButtons(prev => ({ ...prev, [fileIndex]: selectedButtonId }));
    };

    useEffect(() => {
        if (show) {
            const textarea = textareaRef.current;
            textarea.focus();            
        };
    }, [show]); // 添加 show 到依赖数组，以便在 Modal 变为可见时设置焦点
       

    const handleChange = (event) => {
        const newValue = event.target.value;
        setTextValue(newValue);
    };

    const renderTooltipSend = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Alt + S
        </Tooltip>
    );

    const handleKeyDown = (event) => {        
        if (event.key === 'Enter' && !textValue.trim()) {
            event.preventDefault();
        };

        if (((event.altKey || event.ctrlKey) && event.key === 'Enter') ||
            (event.altKey && (event.key === 'S' || event.key === 's'))) {
            handleSendClick();
            event.preventDefault();
        };
    };

    const handleCW = (e) => {
        setcwValue(e.target.value);
    };

    const setButtonClick = (show) => {
        setShowCW(show);
    };      
    
    const handleSendClick = () =>{
        if (textValue && textValue.trim() !== "") {
            setTextareaClassName("form-control border-1 mt-0 chat-main-message-body-container-textarea");

            let message;
            let code;

            if (toolMidjourney.includes(chatClassName)) {
                code = "Midjourney_Graph";
            } else if(toolPhotoMaker.includes(chatClassName)) {
                code = "PhotoMaker"
            } else if(chatClassName.toLowerCase() === "gemini") {
                code = "Gemini"
            } else {
                code = "vision";
            };

            // 在发送消息之前，根据 selectedButtons 为 files 添加 action 属性
            const processedFiles = files.map((file, index) => {
                const selectedAction = selectedButtons[index];
                // console.log(150, selectedAction);
                let action = ''; // 默认值为空字符串
                let cw = ''

                if (selectedAction && selectedAction !== 'graph') {
                    action = `--${selectedAction}`;
                    cw = `--cw ${cwValue}`
                };
                
                return { ...file, action, cw }; // 返回更新后的文件对象
            });

            if (fileType === "image") {
                const text = {
                    code: code,
                    message: textValue.trim(),
                    files: processedFiles,                    
                };

                message = {
                    chatType: code,
                    classID: "",
                    model: code,
                    msgType: "text",
                    text: text,
                    deduct: files.length,
                };
            };
            // console.log(190, message);
            setSendMessage(message);
            setTextValue("");

            onHide();
        } else {
            setTextareaClassName("form-control border-1 mt-0 chat-main-message-body-container-textarea input-unfinish")
        };
    };

    
    // 根据文件类型渲染不同的内容
    const renderContentByFileType = () => {
        if (fileType === "image") {
            return (
                <Carousel
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    autoPlay={isPlaying}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    deviceType={'desktop'}
                    itemClass="carousel-item-padding-40-px"
                    onMouseEnter={() => setIsPlaying(false)}
                    onMouseLeave={() => setIsPlaying(true)}
                >
                    {files.map((fileString, index) => (
                        <ImageActions
                            key={index}
                            fileString={fileString}
                            index={index}
                            chatClassName={chatClassName}
                            selectedButtons={selectedButtons}
                            setSelectedForFile={setSelectedForFile}
                            setButtonClick={setButtonClick}
                        />
                    ))}
                </Carousel>
            );
        };
    };
  
    return (
        <Modal
            className="chat-main-message-body-file-container"
            show={show} onHide={onHide} size="lg" centered
            aria-labelledby="contained-modal-title-vcenter"
            onEntered={() => textareaRef.current && textareaRef.current.focus()}
        >
            <Modal.Body>
                <div className="chat-main-message-body-file-container-body">
                    <Row className="mb-0 pr-3">
                        {renderContentByFileType()}
                    </Row>
                    
                    <Row className="mt-0 ms-2 mr-2 mb-2" hidden={!showCW}>                        
                        <Form.Label className="ms-1">Similarity</Form.Label>
                        <Col xs={10} className="text-start">
                            <Form.Control
                                type="range"
                                className="range-temperature"
                                id="customRange"
                                min="0"
                                max="100"
                                step="1"
                                value={cwValue}
                                onChange={handleCW}                                
                            />
                        </Col>
                        <Col xs={2} className="text-start d-flex align-items-center">
                            <Form.Label className={`text-start ms-1 mt-1`}>{cwValue}</Form.Label>                            
                        </Col>
                    </Row>
                    
                    <Row className="m-2">
                        <p className="text-secondary font-italic ms-2">                    
                            {/* Deduct: {files.length} files, -{price} tokens/file, -{files.length * price} tokens total. */}
                            Deduct: -{price} tokens
                        </p>
                        <Col xs={12} className="chat-main-dialog-container-col">
                            <textarea
                                ref={textareaRef}
                                className={`${textareaClassName}`}
                                id="user-dialog-file"
                                value={textValue}
                                rows="12"
                                placeholder="Type your prompt..."
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                        <Col className="chat-main-dialog-container-col-right">
                            <OverlayTrigger
                                placement="right"
                                overlay={renderTooltipSend}                        
                            >
                                <Button variant="outline-secondary"
                                    className="button-send-file border-0"
                                    onClick={handleSendClick}
                                    ref={sendButtonRef}
                                >
                                    <i className="bi bi-send icon-enlarged"></i>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>                    
                </div>
            </Modal.Body>
        </Modal>
    );
  }
  
  export default FilesContainerSend;