import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import CodeBlock from './code_block.jsx';
import 'katex/dist/katex.min.css'; // 引入 KaTeX 的样式

const MarkdownRender = ({ children }) => {
    const components = {
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
                <CodeBlock
                    language={match[1]}
                    value={String(children)}
                    {...props}
                />
            ) : (
                <code value={String(children)} {...props}>
                    {children}
                </code>
            );
        }
    };

    const formattedResponse = children
        .replace(/\\\[/g, '$$')
        .replace(/\\\]/g, '$$')
        .replace(/\\\(/g, '$')
        .replace(/\\\)/g, '$');

    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkMath]} // 添加 remark 插件
            rehypePlugins={[rehypeKatex]} // 添加 rehype 插件
            components={components}
            breaks={true}>
                {formattedResponse}
        </ReactMarkdown>
    );
};

export default MarkdownRender;