export const baseUrl_api = "https://app.aimindwork.com/chat/api";    //正式环境
export const baseUrl = "https://app.aimindwork.com/chat";

// export const baseUrl_api = "http://localhost:5003/chat/api";    // 本地测试
// export const baseUrl = "http://localhost:443";

// export const baseUrl_api = "https://172.20.94.84:5003/chat/api";    // 本地测试
// export const baseUrl = " https://172.20.94.84:443";

// export const baseUrl_api = "https://chattest.aimindwork.com/chat/api";
// export const baseUrl = "https://chattest.aimindwork.com/chat";


export const apiUrl_api = "https://app.aimindwork.com/api";
export const apiUrl = "https://app.aimindwork.com";

export const chatClassFile = [
    "Midjourney_Graph",
    "ChatGPT_FileAssistant",
    "Vision",
    "PhotoMaker",
    "PhotoMaker_Admin",
    "Translator",
];

export const allowedFileTypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/vnd.ms-outlook',
    'application/pdf', // .pdf
    'text/plain', // .txt
    // 添加图片类型
    'image/jpeg',
    'image/png',
    '.msg',
];

export const fileAccept = {
    image: ['image/jpeg', 'image/png'],
    translator: [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
        'application/vnd.ms-outlook',
        'application/pdf', // .pdf
        'text/plain', // .txt
        '.msg',
    ],
    excel: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
};

export const fileIcon = {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
        icon: <i className="bi bi-file-word fs-1"></i>,
        desc: "file-word"
    },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        icon: <i className="bi bi-file-excel fs-1"></i>,
        desc: "file-excel"
    },
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
        icon: <i className="bi bi-file-ppt fs-1"></i>,
        desc: "file-ppt"
    },
    'application/vnd.ms-outlook': {
        icon: <i className="bi bi-file-richtext fs-1"></i>,
        desc: "file-richtext"
    },
    'application/pdf': {
        icon: <i className="bi bi-file-pdf fs-1"></i>,
        desc: "file-pdf"
    },
    'text/plain': {
        icon: <i className="bi bi-file-text fs-1"></i>,
        desc: "file-text"
    }
};


export const imageChatClassName = [
    "Midjourney_Graph", "Midjourney", "DallE", "PhotoMaker","PhotoMaker_Admin",
];

export const getImageUrlChatClassName = [
    "Midjourney_Graph", "Vision", "PhotoMaker_Admin", "PhotoMaker"
];

export const getImageStreamChatClassName = [
    
];

export const chatClassNameToChatType = {
    DallE: "DallE",
    Midjourney: "Midjourney",
    Midjourney_Graph: "Midjourney_Graph",
    Vision: "Vision",
    PhotoMaker: "PhotoMaker",
    PhotoMaker_Admin: "PhotoMaker_Admin",
    Suno: "Suno",
};

export const loginImages = [
    '/login-show.gif',
    '/login-show.jpg',
    '/logo512.png',
];

export const imageTypes = [
    'image', 'dalle', 'photomaker', 'midjourney'
];


export const toolMidjourney = [
    "Midjourney_Graph", "Midjourney",
];

export const toolPhotoMaker = [
    "PhotoMaker", "PhotoMaker_Admin",
];

export const showParam = [
    "Midjourney_Graph", "Midjourney", "PhotoMaker","PhotoMaker_Admin",
];

export const discordUpload = [
    "Midjourney_Graph", "Midjourney", 
]

export const singleFileUpload = [
    "Translator", "Midjourney_Graph", "Midjourney", "Gemini"
]

export const nonUpload = [
    "DallE", "Midjourney_Graph", "Midjourney", "Suno"
];

export const chatImageMJ = [
    "Midjourney_Graph", "Midjourney", 
];

export const fileDrag = [
    "Vision", "ChatGPT", "Translator", "Gemini"
];

export const chatSpecial = [
    "PhotoMaker"
];

export const chatMedia = [
    "Suno"
];

export const chatArray = [
    "Suno", "Translator"
];

export const chatFile = [
    "Translator"
];

export const fileTranslator = [
    "Translator"
];

export const chatMediaSuno = [
    "Suno"
];

export const floatBilling = [
    "Translator"
];

export const allowedFileDrag = [
    "gemini", "translator"
];

// 图文混合
export const mediaIntegration = [
    "Gemini"
];

export const MUSIC_GENRE_LIST = [
    {code: "African", sort: 1},
    {code: "Asian", sort: 2},
    {code: "South and southeast Asian", sort: 3},
    {code: "Avant-garde", sort: 4},
    {code: "Blues", sort: 5},
    {code: "Caribbean and Caribbean-influenced", sort: 6},
    {code: "Comedy", sort: 7},
    {code: "Country", sort: 8},
    {code: "Easy listening", sort: 9},
    {code: "Electronic", sort: 10},
    {code: "Folk", sort: 11},
    {code: "Hip hop", sort: 12},
    {code: "Jazz", sort: 13},
    {code: "Latin", sort: 14},
    {code: "Pop", sort: 15},
    {code: "R&B and soul", sort: 16},
    {code: "Rock", sort: 17},
];

// You are a Chinese-English translator. You will translate the Chinese content I input into corresponding English. Please note that your translated content mainly serves a painting AI, which can only understand concrete descriptions rather than abstract concepts. Based on your understanding of the painting AI, such as its possible training models and natural language processing methods, you will optimize the translation. As my descriptions may be scattered and incoherent, you need to consider these issues comprehensively and then optimize or reorganize the translated English content to make the painting AI more clear about what I am saying. Please strictly follow this rule for translation and only output the translated English content. For example, if I input: "一只想家的小狗。", you cannot output: "A homesick little dog." You must output: "A small dog that misses home, with a sad look on its face and its tail tucked between its legs. It might be standing in front of a closed door or a gate, gazing longingly into the distance, as if hoping to catch a glimpse of its beloved home." When I input Chinese content, please translate the English content I need.